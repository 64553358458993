import React, { useEffect, useState } from "react";
import { downloadInvoice, makeLivePayment, orderHistory } from "../../../services/checkout";
import { getCurrentDate } from "../../../services/utils/function";
import NoDataFound from "../../../services/utils/noDataPopUp";
import { notificationService } from "../../../services/notifications/notificationService";
import { Box, Grid, Typography } from "@mui/material";
import './order.css';
import LeaveReview from "./leaveReview";
import { Icons } from "../../../images/reactIcons";
import { PDFDocument } from 'pdf-lib';
import { toast } from "react-toastify";

const MyOrder = ({setCheckStatus}) => {
    const [extnd, setExtnd] = useState({ id: null, show: false });
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [productid, setProductId] = useState(0)

    const fetchOrders = () => {
        orderHistory()
            .then(res => {
                if (res?.orders?.constructor === Array) {
                    setData(res?.orders);
                };
            })
            .catch(error => {
                return error;
            })
    };

    const handleLivePayment = async (id) => {
        try {
            const response = await makeLivePayment({ order_id: id });
            
            if (response) {
                toast.success("Your status has been updated!", {
                    onClose: fetchOrders(), 
                    autoClose: 3000, 
                });

                setCheckStatus(true);
                localStorage.setItem('setCheckStatus', true);
            } else {
                toast.error("Failed to update status. Please try again.");
            }
        } catch (error) {
            console.error("Error making payment:", error);
            toast.error("An error occurred while updating the status.");
        }
    };

  const getInvoice = async (id) => {
        try {
            const res = await downloadInvoice(id);
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            const pdfUrl = window.URL.createObjectURL(pdfBlob);        
            const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);         
            const pdfBytes = await pdfDoc.save();
            const pdfBlobWithImage = new Blob([pdfBytes], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(pdfBlobWithImage);
            link.download = 'downloaded_invoice.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            notificationService.sendMessage({ type: "success", title: "Download Invoice", text: "Invoice Downloaded Successfully with Image" });
        } catch (error) {
            console.error('Error downloading PDF:', error);
            notificationService.sendMessage({ type: "error", title: "Download Invoice", text: "Something went wrong, please try again!" });
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);
    const handleLeaveReview = (id) => {
        setProductId(id)
        setOpen(!open)
    }
    return (
        <>
            {open && <LeaveReview
                productId={productid}
                refresh={() => { setOpen(!open) }}
                setShowModal={() => setOpen(!open)} />}

            <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-4">
                <Typography variant="h6" className="font-articulat" style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Greycliff CF' }}>Order History</Typography>
                {data.length !== 0 ? (
                    data.map((ele, index) => {
                        console.log("ele status", ele.status);
                        return (
                            <div key={index.toString()} style={{ border: "1px solid #FBF5F1", marginTop: "20px", borderRadius: "10px", fontFamily: 'Greycliff CF' }}>
                                <Box className={`header-container ${extnd?.id === index && extnd.show ? "head-styl" : "head-instyl"}`}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2} >
                                            <div className="header-tile">Order ID</div>
                                            <div className="header-subtitle">#{ele?.id}</div>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'center' }}>
                                            <div className="header-tile">Order Date</div>
                                            <div className="header-subtitle">{getCurrentDate(ele?.created_at)}</div>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'center' }}>
                                            <div className="header-tile">Delivery Date</div>
                                            <div className="header-subtitle">{getCurrentDate(ele?.delivery_date)}</div>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'center' }}>
                                            <div className="header-tile">Status</div>
                                            <div style={{ fontSize: '12px', fontWeight: '600' }} className={(ele.status === "In Progress" ? "text-[#FFC700]" : (ele?.status === "Delivered" ? "text-[#4ECE00]" : (ele?.status === 'Placed' ? "text-[#163f16]" :  "text-[#FF0000]"))) + " font-semibold mt-1"}>{ele?.status}</div>
                                           
                                        </Grid>
                                        <Grid item xs={1} style={{ textAlign: '-webkit-right' }}>
                                            {extnd?.id === index && extnd.show ? <Icons.IoIosArrowUp className="cursor-pointer w-5 h-5" style={{ color: "#999999" }} aria-hidden="true" onClick={() => setExtnd({ id: index, show: false })}/> : <Icons.IoIosArrowDown className="cursor-pointer w-5 h-5" style={{ color: "#999999" }} aria-hidden="true" onClick={() => setExtnd({ id: index, show: true })}/>}
                                        </Grid>
                                    </Grid>
                                </Box>
                                {extnd?.id === index && extnd.show && (
                                    <>
                                        <Box style={{ padding: '15px', borderBottom: '1px solid #FBF5F1', fontFamily: 'Greycliff CF' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="order-d-title">Delivery Address</div>
                                                    <div className="order-d-stitle">{`${ele?.shipping_address?.address_line_1}, ${ele?.shipping_address?.city}, ${ele?.shipping_address?.state}, ${ele?.shipping_address?.country}. ${ele?.shipping_address?.postal_code}`}</div>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <div className="order-d-title">Order Summary</div>
                                                    <div className="order-d-box">
                                                        <div className="order-d-stitle">Item(s) Subtotal:</div>
                                                        <div className="order-d-stitle text-right">₹{ele?.sub_total}</div>
                                                    </div>
                                                    <div className="order-d-box">
                                                        <div className="order-d-stitle">Discount:</div>
                                                        <div className="order-d-stitle text-right">₹{ele?.discount_amount}</div>
                                                    </div>
                                                    <div className="order-d-box">
                                                        <div className="order-d-stitle">Tax:</div>
                                                        <div className="order-d-stitle text-right">₹{ele?.tax_amount}</div>
                                                    </div>
                                                    <div className="order-d-box">
                                                        <div className="order-d-stitle">Shipping:</div>
                                                        <div className="order-d-stitle text-right">₹{ele?.shipping_fee}</div>
                                                    </div>
                                                    <div className="order-d-box">
                                                        <div className="order-d-stitle">Total:</div>
                                                        <div className="order-d-stitle text-right">₹{ele?.total_amount}</div>
                                                    </div>
                                                    <div className="order-d-box">
                                                        <div className="font-semibold" style={{ fontSize: '12px', fontWeight: "600" }}>Grand Total:</div>
                                                        <div className="text-right font-semibold" style={{ fontSize: '12px', fontWeight: "600" }}>₹{ele?.total_amount}</div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} sm={3}
                                                    style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}
                                                >
                                                    {ele.status === "Delivered" && <div className="order-d-title text-[#009898]">Download Invoice</div>}
                                                    {ele.status === "Delivered" && <div className="text-xl text-[#009898]" style={{ marginRight: '35px' }} onClick={() => { getInvoice(ele?.id) }}><Icons.FaDownload className="cursor-pointer" aria-hidden="true"/></div>}
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {ele?.order_items.map((prod, i) => {
                                            return (
                                                <div key={i}>
                                                    <Grid container spacing={2} style={{ padding: '15px', borderBottom: ele?.order_items.length === (i + 1) ? 'none' : '1px solid #FBF5F1' }}>
                                                        <Grid item xs={3} sm={2}>
                                                            <div>
                                                                <img src={prod?.product?.attachment_url[0]} alt="order-image" loading="lazy" className="order-img" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={6} sm={8}>
                                                            <div>
                                                                <div className="order-d-text">{prod?.product?.name}</div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    marginTop: "15px"
                                                                }} className="product-d-text">
                                                                    <div className="order-d-text"><span className="text-[#999999]">Net wt. : </span><span style={{ fontWeight: "600" }}>{prod?.product?.net_wt + ' ' + prod?.product?.unit}</span></div>
                                                                    <div className="order-d-text"><span className="text-[#999999]">MRP:</span><span style={{ fontWeight: "600" }}> ₹ {((prod?.product.cost_price === null || prod?.product.cost_price === 0.0) ? parseFloat(prod?.product.master_price) : parseFloat(prod?.product.cost_price))} </span><span className="text-[#999999] line-through">  {(prod?.product.cost_price === null || prod?.product.cost_price === 0.0) ? " " : "₹" + parseFloat(prod?.product.master_price)}</span></div>
                                                                    <div className="order-d-text"><span className="text-[#999999]">QTY: </span><span style={{ fontWeight: "600" }}>{prod?.quantity}</span></div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={3} sm={2}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "flex-end",
                                                                    textAlign: 'end'
                                                                }}
                                                            >
                                                                <button className="border border-[#4ECE00] text-[#4ECE00] rounded-xl order-d-text" style={{ padding: '3px 17px', fontWeight: '600' }}>₹{prod?.total_price}</button>
                                                                <button className="order-d-text text-[#009898] mt-3" onClick={() => handleLeaveReview(prod?.product?.id)}>Leave review</button>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )
                                        })}
                                    </>
                                )}

                            </div>
                        )
                    })
                ) : (
                    <NoDataFound />
                )}
            </div>
        </>
    )
};

export default MyOrder;