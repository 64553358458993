import React, { useState } from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import { Box, Divider, Grid, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
  const [tab, setTab] = useState({ idx: null, show: false });


  const handleTabs = (index) => {
    if (index === tab.idx) {
      setTab((prevState) => ({ ...prevState, show: !prevState.show }));
    } else {
      setTab({ idx: index, show: true });
    }
  };

  return (
    <footer className="footer-container">
      <Grid container className='large'>
        <Grid item xs={12} sm={6} md={3} className="footer-section">
          <Typography variant="h6" className="footer-section-title">HELP</Typography>
          <Link to="/orderstatus" className="footer-link">
            <Typography variant="span">Order Status</Typography>
          </Link>
          <Link to="/dispatchdelivery" className="footer-link">
            <Typography variant="span">Dispatch and Delivery</Typography>
          </Link>
          <Link to="/returncancellation" className="footer-link"><Typography variant="span">Return and Cancellation</Typography></Link>
          <Link to="/feedback" className="footer-link"><Typography variant="span">Feedback</Typography></Link>
          <Link to="/faq" className="footer-link"><Typography variant="span">FAQ</Typography></Link>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className="footer-section">
          <Typography variant="h6" className="footer-section-title">ABOUT</Typography>
          <Link to="/ourstory" className="footer-link"><Typography variant="span">Our Story</Typography></Link>
          <Link to="/ourblogs" className="footer-link"><Typography variant="span">Our Blogs</Typography></Link>
          <Link to="/reviews" className="footer-link"> <Typography variant="span">Reviews</Typography></Link>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className="footer-section">
          <Typography variant="h6" className="footer-section-title">
            RESOURCES
          </Typography>
          {/* <Link to="/nutritionistconsultation" className="footer-link"><Typography variant="span">Nutritionist</Typography></Link> */}
          <Link to="/Subscribe" className="footer-link"><Typography variant="span">Subscribe</Typography></Link>
          {/* <Link to="/referfriend" className="footer-link"><Typography variant="span">Refer a Friend</Typography></Link> */}
        </Grid>

        <Grid item xs={12} sm={6} md={3} className="footer-section">
          <Typography variant="h6" className="footer-section-title">
            SUPPORT
          </Typography>
          <Typography sx={{ color: '#D7B57F', fontSize: '14px', fontWeight: 400, fontFamily: 'Greycliff CF' }}>Got Questions? Call us (9:00 AM to 6:00 PM)</Typography>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', marginTop: '8px' }}>
            <PhoneIcon sx={{ color: '#ffffff', marginRight: '8px', fontSize: '18px' }} />
            <Typography sx={{ color: '#ffffff', fontSize: '14px', fontWeight: 400, fontFamily: 'Greycliff CF' }}>
              (+91) 9084412233
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <EmailIcon sx={{ color: '#ffffff', marginRight: '8px', fontSize: '18px' }} />
            <Typography sx={{ color: '#ffffff', fontSize: '14px', fontWeight: 400, fontFamily: 'Greycliff CF' }}>
              support@grindpound.com
            </Typography>
          </div>
          <br />
          <Typography sx={{ color: '#D7B57F', fontSize: '14px', fontWeight: 400, fontFamily: 'Greycliff CF' }}>Address info</Typography>
          <Typography sx={{ color: '#ffffff', fontSize: '14px', fontWeight: 400, fontFamily: 'Greycliff CF' }}>Grind & Pound Pvt Ltd, M.G. Tower, Anand Ashram Road, Shyamganj, Bareilly – 243005, UP, India
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider className="footer-divider" />
        </Grid>

        <Grid item xs={12}>
          <Grid container className="footer-policy">
            <Grid item xs={12} sm={6} md={8} lg={9} style={{ paddingRight: '15px' }}>
              <Link to="/privacypolicy" className="footer-policy-link"><Typography variant="span" style={{ paddingRight: '15px', borderRight: '1px solid #fff' }}>Privacy Policy</Typography></Link>
              <Link to="/termsandconditions" className="footer-policy-link"><Typography variant="span" style={{ paddingLeft: '15px', paddingRight: '15px' }}>Terms and Conditions</Typography></Link>
              {/* <Typography variant="span" onClick={(e) => handleNavigate(e, "/termsofuse")} className="footer-policy-link" style={{ paddingLeft: '15px' }}>Terms of Use</Typography> */}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1" sx={{ '@media (max-width:600px)': { marginTop: '10px' } }} className="footer-policy-text">Copyright @2024. All rights reserved</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className='small'>
        <Grid item xs={12} sm={6} md={3} className="footer-section">
          <Box onClick={() => handleTabs(0)} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" className="footer-section-title">HELP</Typography>
            {tab?.idx === 0 && tab?.show ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </Box>
          {tab?.idx === 0 && tab?.show && (
            <Box>
              <Link to="/orderstatus" className="footer-link"><Typography variant="span">Order Status</Typography></Link>
              <Link to="/dispatchdelivery" className="footer-link"><Typography variant="span">Dispatch and Delivery</Typography></Link>
              <Link to="/returncancellation" className="footer-link"><Typography variant="span">Return and Cancellation</Typography></Link>
              <Link to="/feedback" className="footer-link"><Typography variant="span">Feedback</Typography></Link>
              <Link to="/faq" className="footer-link"><Typography variant="span">FAQ</Typography></Link>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3} className="footer-section">
          <Box onClick={() => handleTabs(1)} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" className="footer-section-title">ABOUT</Typography>
            {tab?.idx === 1 && tab?.show ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </Box>
          {tab?.idx === 1 && tab?.show && (
            <Box>
              <Link to="/ourstory" className="footer-link"><Typography variant="span">Our Story</Typography></Link>
              <Link to="/ourblogs" className="footer-link"><Typography variant="span">Our Blogs</Typography></Link>
              <Link to="/reviews" className="footer-link"> <Typography variant="span">Reviews</Typography></Link>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3} className="footer-section">
          <Box onClick={() => handleTabs(2)} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" className="footer-section-title">RESOURCES</Typography>
            {tab?.idx === 2 && tab?.show ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </Box>
          {tab?.idx === 2 && tab?.show && (
            <Box>
              {/* <Link to="/nutritionistconsultation" className="footer-link"> <Typography variant="span">Nutritionist</Typography></Link> */}
              <Link to="/Subscribe" className="footer-link"> <Typography variant="span">Subscribe</Typography></Link>
              {/* <Link className="footer-link" to="/referfriend"> <Typography variant="span">Refer a Friend</Typography></Link> */}
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3} className="footer-section">
          <Box onClick={() => handleTabs(3)} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" className="footer-section-title">SUPPORT</Typography>
            {tab?.idx === 3 && tab?.show ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </Box>
          {tab?.idx === 3 && tab?.show && (
            <Box>
              <Typography sx={{ color: '#D7B57F', fontSize: '14px', fontWeight: 400 }}>Got Questions? Call us (9:00 AM to 6:00 PM)</Typography>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', marginTop: '8px' }}>
                <PhoneIcon sx={{ color: '#ffffff', marginRight: '8px', fontSize: '18px' }} />
                <Typography sx={{ color: '#ffffff', fontSize: '14px', fontWeight: 400, fontFamily: 'Greycliff CF' }}>
                  (+91) 9084412233
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon sx={{ color: '#ffffff', marginRight: '8px', fontSize: '18px' }} />
                <Typography sx={{ color: '#ffffff', fontSize: '14px', fontWeight: 400, fontFamily: 'Greycliff CF' }}>
                  support@grindpound.com
                </Typography>
              </div>
              <br />
              <Typography sx={{ color: '#D7B57F', fontSize: '14px', fontWeight: 400 }}>Address info</Typography>
              <Typography sx={{ color: '#ffffff', fontSize: '14px', fontWeight: 400 }}>Grind & Pound Pvt Ltd, M.G. Tower, Anand Ashram Road, Shyamganj, Bareilly – 243005, UP, India </Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider className="footer-divider" />
        </Grid>

        <Grid item xs={12}>
          <Grid container className="footer-policy">
            <Grid item xs={12} sm={6} md={8} lg={9} style={{ paddingRight: '15px' }}>
              <Link className="footer-policy-link" to="/privacypolicy"><Typography variant="span" style={{ paddingRight: '15px', borderRight: '1px solid #fff' }}>Privacy Policy</Typography></Link>
              <Link className="footer-policy-link" to="/termsandconditions"><Typography variant="span" style={{ paddingLeft: '15px', paddingRight: '15px', borderRight: '1px solid #fff' }}>Terms and Conditions</Typography></Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1" sx={{ '@media (max-width:600px)': { marginTop: '10px' } }} className="footer-policy-text">Copyright @2024. All rights reserved</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
