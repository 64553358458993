import { useDispatch } from "react-redux";
import Review from "../../../view/private/Shipping/review";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentDate } from "../../../services/utils/function";
import { resetCartData } from "../../../reducers/cart/actions";
import ApplicationHelmet, { navigateToNext } from "../../../navigation/navigation";
import { getOrderDetails, makeLivePayment } from '../../../services/checkout';
import "./orderreceived.css";
import '../Shipping/review.css'
import { Grid } from "@mui/material";

const OrderReceived = (props) => {
  const [orderStatus, setOrderStatus] = useState('');
  const [orderState, setOrderState] = useState('');
  const [orderDetails, setOrderDetails] = useState([]);
  const orderDeliveryDate =localStorage.getItem("orderdate")
  const [discount, setDiscount] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const checkOrderStatus = async () => {
    const response = await makeLivePayment({ order_id: id });
    const details = await getOrderDetails(id);
    setOrderDetails(details?.data?.order);
    setOrderState(response?.status)
    if (response?.status === 'SUCCESS') {
      setOrderStatus('Thank you. Your order has been received');
      dispatch(resetCartData());
    } else {
      if (response?.status === 'FAILED') {
        setOrderStatus('Your Payment is failed');
      } else if (response?.status === 'USER_DROPPED') {
        setOrderStatus('Your Payment is Unsuccessful');
      } else if (response?.status === 'PENDING') {
        setOrderStatus('Your Payment is Pending');
      }
      else {
        setOrderStatus('Your Payment is Unsuccessful');
      }
    }
  }
  
  useEffect(() => {
    checkOrderStatus();
  }, [])

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setDiscount(location?.state?.discount_amount);
  }, [location]);

  const resetCart = (e) => {
    dispatch(resetCartData());
    navigateToNext(e, "/home", navigate);
  };
  useEffect(() => {
    setDiscount(location?.state?.discount_amount);
  }, [location]);
  useEffect(() => {
    if (orderDeliveryDate) {
      localStorage.removeItem("orderdate");
    }
  }, [orderDeliveryDate]);

  return (
    <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 p-5 mt-36 font-articulat">
      <ApplicationHelmet/>
      <div className="orderreceivedcotainder">
        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 text-lg font-articulat font-semibold .tracking-tighter">
          {orderStatus === 'Thank you. Your order has been received' ? <i
            className="fa fa-check-circle text-[#4ECE00]"
            aria-hidden="true"
          ></i> : (orderState === 'USER_DROPPED' && <i
            className="fa fa-times-circle text-[#2B5DF8 ]"
            aria-hidden="true"
          ></i>) || ((orderState === 'FAILED' || (orderState === undefined || null)) && <i
            className="fa fa-times-circle text-[#FF0000]"
            aria-hidden="true"
          ></i>) || (orderState === 'PENDING' && <i
            className="fa fa-times-circle text-[#FCBC2B]"
            aria-hidden="true"
          ></i>)} {" "}
          <span className={orderState === 'USER_DROPPED' ? 'text-[#2B5DF8]' : orderState === 'FAILED' ? 'text-[#FF0000]' : orderState === 'PENDING' ? 'text-[#FCBC2B]' : orderStatus !== 'Thank you. Your order has been received' ? 'text-[#FF0000]' : ''}>
            {orderStatus}
          </span>
        </div>
        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 text-lg font-articulat font-semibold">
          <hr />
        </div>
        <div className="lg:col-span-2 md:col-span-3 sm:col-span-3 text-sm mt-2">
          <div className="font-normal text-[#999999]">Order ID</div>
          <div className="font-semibold mt-1">#{orderDetails?.id}</div>
        </div>
        <div className="lg:col-span-2 md:col-span-3 sm:col-span-3 text-sm mt-2">
          <div className="font-normal text-[#999999]">Order Date</div>
          <div className="font-semibold mt-1">{getCurrentDate(new Date())}</div>
        </div>
        <div className="lg:col-span-2 md:col-span-3 sm:col-span-3 text-sm mt-2">
          <div className="font-normal text-[#999999]">Delivery Date</div>
          <div className="font-semibold mt-1">{new Date(orderDetails?.delivery_date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}</div>
        </div>
        <div className="lg:col-span-2 md:col-span-3 sm:col-span-3 text-sm mt-2">
          <div className="font-normal text-[#999999]">Total</div>
          <div className="font-semibold mt-1">₹ {orderDetails?.total_amount}</div>
        </div>
        <div className="lg:col-span-2 md:col-span-3 sm:col-span-3 text-sm mt-2">
          <div className="font-normal text-[#999999]">Payment method</div>
          <div className="font-semibold mt-1">{orderDetails?.payment_method && orderDetails.payment_method
            .toUpperCase()
            .replace(/_/g, ' ')}</div>
        </div>
        <div className="lg:col-span-2 md:col-span-6 sm:col-span-6 text-sm mt-2">
          <div className="font-normal text-[#999999]">Address</div>
          <div className="font-semibold mt-1">
            <span className="text-[#000000]">
              {(orderDetails?.shipping_address?.address_line_1 !== null ? orderDetails?.shipping_address?.address_line_1 : "") + " " + (orderDetails?.shipping_address?.address_line_2 !== null ? orderDetails?.shipping_address?.address_line_2 : "") + " " + (orderDetails?.shipping_address?.city !== null ? orderDetails?.shipping_address?.city : "") + " " + (orderDetails?.shipping_address?.state !== null ? orderDetails?.shipping_address?.state : '') + " " + (orderDetails?.shipping_address?.postal_code !== null ? orderDetails?.shipping_address?.postal_code : '')
              }
            </span>{" "}
          </div>
        </div>

        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-3 text-lg font-articulat font-semibold">
          <hr />
        </div>
      </div>
      <div className="orderreceivedcotainder-1">
        <Grid container>
          <Grid item xs={12} sm={12}>
            {orderStatus === 'Thank you. Your order has been received' ? <i
              className="fa fa-check-circle text-[#4ECE00]"
              aria-hidden="true"
            ></i> : (orderState === 'USER_DROPPED' && <i
              className="fa fa-times-circle text-[#2B5DF8 ]"
              aria-hidden="true"
            ></i>) || (orderState === 'FAILED' || (orderState === undefined || null) && <i
              className="fa fa-times-circle text-[#FF0000]"
              aria-hidden="true"
            ></i>) || (orderState === 'PENDING' && <i
              className="fa fa-times-circle text-[#FCBC2B]"
              aria-hidden="true"
            ></i>)} {" "}
            <span className={`ordercontentsize ${orderState === 'USER_DROPPED' ? 'text-[#2B5DF8]' : orderState === 'FAILED' ? 'text-[#FF0000]' : orderState === 'PENDING' ? 'text-[#FCBC2B]' : orderStatus !== 'Thank you. Your order has been received' ? 'text-[#FF0000]' : ''}`}>{orderStatus}</span>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex" }} mt={2}>
            <Grid item xs={6} sm={6}>
              <div className="font-normal text-[#999999] ordercontentname">
                Order ID
              </div>
              <div className="font-semibold mt-1 ordercontentvalues">
                #{orderDetails?.id}
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div className="font-normal text-[#999999] ordercontentname">
                Order Date
              </div>
              <div className="font-semibold mt-1 ordercontentvalues">
                {getCurrentDate(new Date())}
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div className="font-normal text-[#999999] ordercontentname">
                Delivery Date
              </div>
              <div className="font-semibold mt-1 ordercontentvalues">
                {new Date(orderDetails?.delivery_date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ display: "flex" }}>
            <Grid item xs={6} sm={6}>
              <div className="font-normal text-[#999999] ordercontentname">
                Total
              </div>
              <div className="font-semibold mt-1 ordercontentvalues">
                ₹ {orderDetails?.total_amount}
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div className="font-normal text-[#999999] ordercontentname">
                Payment method
              </div>
              <div className="font-semibold mt-1 ordercontentvalues">
                {orderDetails?.payment_method && orderDetails.payment_method
                  .toUpperCase()
                  .replace(/_/g, ' ')}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} mb={2}>
            <div className="font-normal text-[#999999] ordercontentname">
              Address
            </div>
            <div className="font-semibold mt-1">
              <span className="text-[#FF0000] ordercontentvalues">
                {(orderDetails?.shipping_address?.address_line_1 !== null ? orderDetails?.shipping_address?.address_line_1 : "") + " " + (orderDetails?.shipping_address?.address_line_2 !== null ? orderDetails?.shipping_address?.address_line_2 : "") + " " + (orderDetails?.shipping_address?.city !== null ? orderDetails?.shipping_address?.city : "") + " " + (orderDetails?.shipping_address?.state !== null ? orderDetails?.shipping_address?.state : '') + " " + (orderDetails?.shipping_address?.postal_code !== null ? orderDetails?.shipping_address?.postal_code : '')
                }
              </span>{" "}
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="lg:col-span-9 md:col-span-8 sm:col-span-8 mt-1 ">
        <Review order={true} orderDetails={orderDetails?.order_items || []} />
      </div>
      <div className="lg:col-span-3 md:col-span-4 sm:col-span-4 mt-1 text-sm font-articulat font-semibold m-2 orderreceivedpaymainbox ">
        <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12">
          <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 p-3"></div>
          <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 bg-[#FBF5F1] p-2 text-lg orderpaymentdetails">
            Payment Detail
          </div>
          <div className="orderreceivedpaybox">
            <div className="lg:col-span-8 md:col-span-8 sm:col-span-8 p-2 text-sm orderpaymentboxname">
              Sub Total
            </div>
            <div className="lg:col-span-4 md:col-span-4 sm:col-span-4 text-xs font-articulat font-normal p-2 orderpaymentboxvalue">
              ₹{orderDetails?.sub_total}
            </div>
          </div>
          <div className="orderreceivedpaybox">
            <div className="lg:col-span-8 md:col-span-8 sm:col-span-8 p-2 text-sm orderpaymentboxname">
              Discount
            </div>
            <div className="lg:col-span-4 md:col-span-4 sm:col-span-4 text-xs font-articulat font-normal p-2 orderpaymentboxvalue">
              - ₹{orderDetails?.discount_amount}
            </div>
          </div>
          <div className="orderreceivedpaybox">
            <div className="lg:col-span-8 md:col-span-8 sm:col-span-8 p-2 text-sm orderpaymentboxname">
              Shipping Cost
            </div>
            <div className="lg:col-span-4 md:col-span-4 sm:col-span-4 text-xs font-articulat font-normal p-2 orderpaymentboxvalue">
              {orderDetails?.shipping_fee}
            </div>
          </div>
          <div className="orderreceivedpaybox">
            <div className="lg:col-span-8 md:col-span-8 sm:col-span-8 p-2 text-sm orderpaymentboxname">
              Tax Included
            </div>
            <div className="lg:col-span-4 md:col-span-4 sm:col-span-4 text-xs font-articulat font-normal p-2 orderpaymentboxvalue">
              ₹ 0.00
            </div>
          </div>
          <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 bg-[#FBF5F1] p-2 text-lg orderreceivedpaybox">
            <div className="lg:col-span-8 md:col-span-8 sm:col-span-8 bg-[#FBF5F1] p-2 text-lg orderpaymentdetails">
              Grand Total
            </div>
            <div className="lg:col-span-4 md:col-span-4 sm:col-span-4 text-sm font-articulat font-semibold bg-[#FBF5F1] p-2 orderpaymentdetailsvalue">
              ₹{orderDetails?.total_amount}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 mt-2">
        <hr />
      </div>
      <div className="lg:col-span-4 md:col-span-3 sm:col-span-3"></div>
      <button
        className="mt-2 lg:col-span-4 md:col-span-6 sm:col-span-6 focus:outline-none font-md w-full rounded-lg text-sm px-5 py-2.5 p-1 buttoncommon border-[#005956] focus:ring-4 focus:ring-[#005956] text-white"
        onClick={(e) => resetCart(e)}
      >
        Continue Shopping
      </button>
      <div className="lg:col-span-4 md:col-span-3 sm:col-span-3"></div>
    </div>
  );
};

export default OrderReceived;
